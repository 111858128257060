.sectionContainer {
  display: flex;
  flex-direction: column;
}

.subsectionContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.title {
  font-size: 0.9rem;
  font-weight: 200;
  margin-top: 0.4rem;
}

@media (min-width: 481px) and (max-width: 1023px) {
  .sectionContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .subsectionContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    width: 50%;
  }
}
