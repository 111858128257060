body {
  background-color: var(--background-color) !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-color: #F3F2F7;
  --foreground-color: #FFFFFF;
  --primary-text: #2E4053;
  --secondary-text: #F3F2F7;
  --placeholder-text: #8B8B8E;
  --primary-blue: #1888E2;
  --secondary-blue: #52A6E9;
  --opaque-prim-blue: rgba(24, 136, 226, 0.3);
  --text-hover: #0C4471;
  --github: #000000;
  /*--toggle-fg: #f0eb9d;*/
  --toggle-bg: #ffd000;
}

[data-theme="dark"] {
  --background-color: #000000;
  --foreground-color: #1C1C1E;
  --primary-text: #E3EBF3;
  --secondary-text: #000000;
  --placeholder-text: #9B9BA1;
  --secondary-blue: #52A6E9;
  --primary-blue: #1888E2;
  --opaque-prim-blue: rgba(24, 136, 226, 0.2);
  --text-hover: #B4CEE3;
  --github: #FFFFFF;
  /*--toggle-fg: #283452;*/
  --toggle-bg: #00a6ff;
}
