.text {
  font-size: 0.8rem;
  margin: 1rem 0;
}

.footer {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-text);
  bottom: 0;
  width: 100%;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}
