.photoAndName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  margin: 0 0 0 1rem;
  font-size: 3rem;
  font-weight: 300;
}

.firstName {
  color: var(--primary-blue);
  font-weight: 700;
}

.blueText {
  color: var(--primary-blue);
}

.introContainer {
  background-color: var(--background-color);
  color: var(--primary-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

.mainCardsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 0 0;
}

.position {
  font-size: 1.2rem;
  font-weight: 200;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}

.text {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.image {
  border-radius: 10rem;
  flex: 0 0 auto;
  height: 5rem;
  width: 5rem;
  font-size: small;
}

.skillsAndTech {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

@media (min-width: 481px) and (max-width: 1084px) {
  .introContainer {
    @media (max-width: 505px) {
      flex-direction: column;
    }
  }
}
