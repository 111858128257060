.connectLinksContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5rem;
  margin: 1rem 0 0 0;
  width: 100%;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-text);
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

.link:hover {
  color: var(--secondary-blue);
}

.linkText {
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 0.4rem;
}
